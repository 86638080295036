import API from "@utils/request";
import { PAGE_SIZE } from "@config";

const STUNDENT = "/education/stuuser/";

/**
 * 获取学生列表（分页）
 * @param {object} params
 * @param {object} pages
 */
export async function stuuserList(params) {
  try {
    return await API.get(STUNDENT + "list", {
      params: {
        ...params,
        headers: { isLoading: true },
      },
    });
  } catch (error) {
    return error;
  }
}


/**
 * 获取用户详情
 * @param {number} id
 */
export async function studentsInfo(id) {
  try {
    return await API.post(STUNDENT + "info", {
      stuser_id:id,
    });
  } catch (error) {
    return error;
  }
}

/**
 * 导出
 * @param {object} param
 */
export async function exportData(param) {
  try {
    return await API.post(STUNDENT + "export", param);
  } catch (error) {
    return error;
  }
}
/**
 * 获取用户详情统计信息
 * @param {number} id
 */
export async function getUserStudy(params) {
  try {
    return await API.post(STUNDENT + "get_user_study", params);
  } catch (error) {
    return error;
  }
}
