<!--
 * @Author: Dyf
 * @LastEditors: wang hai yang
 * @Date: 2023-03-06 15:39:56
 * @LastEditTime: 2023-05-18 17:34:28
 * @Descripttion: 学生详情
-->
<style lang="scss" scoped>
    .el-form-item__content {
        width: 250px;
    }

    .el-form.readonly p {
        flex-grow: 1;
        height: 46px;
        background-color: #f1f2f8;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 0 28px;
        color: #222121;
        line-height: 46px;
    }

    .student-detail {
        @include innerPage(40px 32px 48px 15px);
        @include pageTitle(0);

        .detail-wrapper {
            width: calc(100% - 15px);
            height: calc(100% - 74px);
            margin-top: 36px;
            overflow: hidden;
            overflow-y: auto;
            box-sizing: border-box;
            padding-right: 58px;

            .info {

                &--base,
                &--study {
                    width: 100%;
                    box-sizing: border-box;
                    padding-left: 12px;
                }

                &--base {
                    margin-bottom: 28px;

                    .el-form {
                        width: 908px;
                        justify-content: space-between;
                    }

                    .el-form-item {
                        width: 444px;
                        margin-bottom: 20px;
                    }
                }

                &--study {
                    margin-top: 34px;

                    .search-form {
                        width: 66.35%;
                        @include flexBox;

                        .el-form {
                            flex-grow: 1;
                            min-width: 500px;

                            &-item {
                                flex-grow: 1;
                            }
                        }

                        ::v-deep .el-input__inner {
                            background: #f1f2f8;
                        }

                        .el-button {
                            flex-shrink: 0;
                        }
                    }

                    .statistical {
                        &-wrapper {
                            margin-top: 24px;
                            @include flexBox(flex-start, flex-start);

                            h5 {
                                line-height: 36px;
                                color: #282828;
                            }
                        }

                        &-left {
                            width: 440px;
                            flex-shrink: 0;
                            margin-right: 16px;

                            li {
                                height: 302px;
                                margin-top: 14px;
                                background: #f1f2f8;
                                border-radius: 10px;
                                box-sizing: border-box;
                                padding: 30px 30px 0;

                                &:first-child {
                                    margin-top: 0;
                                }

                                .progress {
                                    width: 160px;
                                    height: 160px;
                                    position: relative;
                                    margin: 20px auto 0;

                                    .center-text {
                                        width: 100%;
                                        height: 100%;
                                        position: absolute;
                                        left: 0;
                                        top: 0;
                                        flex-direction: column;
                                        @include flexBox(center);

                                        .percentage {
                                            font-size: 30px;
                                            color: #f66478;
                                            line-height: 1em;

                                            span {
                                                font-size: 14px;
                                            }
                                        }
                                    }
                                }

                                &.performance .no-data--empty {
                                    margin-top: 20px;

                                    img {
                                        width: 160px;
                                    }
                                }

                                .performance-echarts {
                                    width: 100%;
                                    height: calc(100% - 36px);
                                }
                            }
                        }

                        &-right {
                            flex-grow: 1;
                            height: 618px;
                            background: #fff;
                            border-radius: 10px;
                            box-sizing: border-box;
                            padding: 32px 36px 0;

                            .tabs {
                                width: calc(100% - 68px);
                                height: 68px;
                                margin-left: 68px;
                                border-bottom: 1px solid rgba($color: #646464, $alpha: 0.05);
                                position: relative;
                                margin-top: 6px;
                                @include flexBox;

                                &:after {
                                    content: '';
                                    width: 38px;
                                    height: 5px;
                                    border-radius: 2.5px;
                                    background: #58419c;
                                    position: absolute;
                                    left: 12px;
                                    bottom: -2px;
                                    @include defaultAni;
                                }

                                &.exam::after {
                                    transform: translateX(160px);
                                }

                                li {
                                    cursor: pointer;
                                    color: #373639;

                                    &:first-child {
                                        padding-right: 52px;
                                    }

                                    &:last-child {
                                        padding-left: 52px;
                                        position: relative;

                                        &::after {
                                            content: '';
                                            height: 20px;
                                            border-left: 1px solid rgba($color: #7a7a7a, $alpha: 0.2);
                                            position: absolute;
                                            left: 0;
                                            top: 50%;
                                            transform: translateY(-50%);
                                        }
                                    }

                                    &.current,
                                    &:not(.current):hover {
                                        color: #6c4ecb;
                                    }
                                }
                            }

                            .trend {
                                &-type {
                                    height: 58px;
                                    color: #292929;
                                    @include flexBox(flex-end);

                                    li {
                                        cursor: pointer;
                                        margin-left: 16px;
                                    }

                                    .current,
                                    li:not(.current):hover {
                                        color: #6340c8;
                                    }
                                }

                                &-echarts {
                                    width: 100%;
                                    height: calc(100% - 190px);
                                    position: relative;

                                    &--container {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .custom-loading {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #fff;
        z-index: 10;
    }
</style>

<template>
    <section class="student-detail">
        <div class="page-title">
            <h3>基本信息</h3>
            <el-button type="custom_primary" size="small" @click="$router.go(-1)">
                返回
            </el-button>
        </div>
        <div class="detail-wrapper">
            <div class="info--base">
                <el-form class="readonly" label-width="82px" ref="baseInfo" inline :model="baseInfo">
                    <el-form-item label="学生姓名">
                        <p>{{ baseInfo.stuser_name }}</p>
                    </el-form-item>
                    <el-form-item label="性别">
                        <p>{{ baseInfo.stuser_sex == 10 ? "男" : "女" }}</p>
                    </el-form-item>
                    <!-- <el-form-item label="身份证号码">
                        <p>{{baseInfo.stuser_idcard}}</p>
                    </el-form-item> -->
                    <el-form-item label="学号">
                        <p>{{ baseInfo.stuser_stuno }}</p>
                    </el-form-item>
                    <el-form-item label="省市区">
                        <p>{{ baseInfo.stuser_province_city_area }}</p>
                    </el-form-item>
                    <el-form-item label="班级">
                        <p>{{ baseInfo.class_name }}</p>
                    </el-form-item>
                    <el-form-item label="入学时间">
                        <p>{{ baseInfo.stuser_entertime }}</p>
                    </el-form-item>
                    <el-form-item label="状态">
                        <p>{{ baseInfo.stuser_readstatus == 10 ? "在读" : "休学" }}</p>
                    </el-form-item>
                </el-form>
            </div>
            <div class="page-title">
                <h3>学习信息</h3>
            </div>
            <div class="info--study">
                <div class="search-form">
                    <el-form inline ref="searchForm" :model="searchForm">
                        <el-form-item>
                            <el-select v-model="searchForm.grade" placeholder="选择年级">
                                <el-option v-for="item in gradeList" :key="item.grade_id" :label="item.grade_name"
                                           :value="item.grade_id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-select v-model="searchForm.semester" placeholder="选择学期">
                                <el-option label="上学期" :value="1"></el-option>
                                <el-option label="下学期" :value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-select v-model="searchForm.sysub_id" placeholder="选择科目">
                                <el-option label="全部科目" :value="0"></el-option>
                                <el-option v-for="item in subjectList" :key="item.sysub_id" :label="item.sysub_name"
                                           :value="item.sysub_id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-button type="custom_primary" size="medium" @click="getUserDetail">搜索</el-button>
                    </el-form>
                </div>
                <div class="statistical-wrapper">
                    <ul class="statistical-left">
                        <li class="pass-rate">
                            <h5 class="bold">考试成绩合格率</h5>
                            <div class="progress">
                                <el-progress class="percentage-progress danger" :width="160" :stroke-width="20"
                                             type="circle" stroke-linecap="butt" :percentage="user_study.pass_rate"
                                             :show-text="false"/>
                                <div class="center-text">
                                    <p class="pf_bold percentage">{{ user_study.pass_rate }}<span class="pf">%</span>
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li class="performance">
                            <h5 class="bold">作业成绩情况</h5>
                            <div class="performance-echarts" ref="performance"></div>
                        </li>
                    </ul>
                    <div class="statistical-right">
                        <h5 class="bold">成绩趋势</h5>
                        <ul :class="scoreType == 2 ? 'tabs exam' : 'tabs'">
                            <li :class="{ current: scoreType == 1 }" @click="changeScore(1)">作业成绩</li>
                            <li :class="{ current: scoreType == 2 }" @click="changeScore(2)">考试成绩</li>
                        </ul>
                        <ul class="trend-type pf">
                            <li :class="{ current: trendType == 7 }" @click="changeTrend(7)">本周成绩</li>
                            <li :class="{ current: trendType == 30 }" @click="changeTrend(30)">本月成绩</li>
                            <li :class="{ current: trendType == -1 }" @click="changeTrend(-1)">本学期成绩</li>
                        </ul>
                        <div class="trend-echarts">
                            <div class="custom-loading" v-if="loading">
                                <div class="point-item"></div>
                                <div class="point-item"></div>
                                <div class="point-item"></div>
                            </div>
                            <div class="trend-echarts--container" ref="trend"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import {dateDiff} from "@utils"
    import {studentsInfo, getUserStudy} from "@api/student";
    import {mapState} from "vuex";

    export default {
        name: 'user_studentDetail',
        data() {
            return {
                baseInfo: {},
                searchForm: {sysub_id: 0, semester: 1, grade: 1},
                trendType: 7, // 7: 本周  30: 本月  -1: 本学期
                scoreType: 2,// 1:作业成绩 2:考试成绩
                loading: false,
                user_study: {sh_data: []},
            }
        },
        computed: {
            ...mapState("common", ["gradeList", "subjectList"])

        },
        // mounted() {
        //     this.performanceEcharts();
        //     this.trendEcharts();
        // },
        async created() {
            await this.init();
            await this.getUserDetail();
        },
        methods: {
            /** 作业成绩情况图表 */
            performanceEcharts() {
                let data = []
                this.user_study.sh_data.map(item => data.push({
                    value: item.count,
                    name: `${item.sthom_wor_grade} ${item.count}次`
                }))
                let echarts = this.$echarts.init(this.$refs.performance)
                let option = {
                    color: ['#f66478', '#6c4ecb', '#fcb332', '#2ac293'],
                    tooltip: {
                        show: false
                    },
                    legend: {
                        itemGap: 30,
                        icon: 'circle',
                        show: true,
                        bottom: 24,
                        itemWidth: 10,
                        itemHeight: 10,
                        textStyle: {
                            fontSize: 14,
                            color: '#333333',
                            rich: {
                                a: {
                                    verticalAlign: 'middle',
                                },
                            },
                            lineHeight: 10,
                            padding: [0, 0, -4, 4],
                        },
                    },
                    series: [
                        {
                            type: 'pie',
                            radius: [60, 82],
                            top: -64,
                            avoidLabelOverlap: false,
                            label: {
                                show: false,
                            },
                            labelLine: {
                                show: false
                            },
                            data: [...data]
                        }
                    ]
                };
                echarts.setOption(option)
            },
            /** 成绩趋势图表 */
            trendEcharts() {
                // let oneDay = 24 * 3600 * 1000;
                let date = [];
                let data = [];
                // let monthTotal = this.$day(new Date()).daysInMonth(); // 本月总天数
                // let termTotal = dateDiff('2023-02-13', '2023-07-13'); // 本学期总天数
                // switch (this.trendType) {
                //     case 1:
                //         for (let i = 0; i < monthTotal; i++) {
                //             const base = new Date(`${this.$day(new Date()).format('YYYY/MM')}/01`).getTime();
                //             let now = new Date((base += oneDay * i));
                //             date.push(this.$day(now).format('YYYY/MM/DD'));
                //         }
                //         break;
                //     case 2:
                //         for (let i = 0; i <= termTotal; i++) {
                //             const base = new Date('2023-02-13').getTime();
                //             let now = new Date((base += oneDay * i));
                //             date.push(this.$day(now).format('YYYY/MM/DD'));
                //         }
                //         break;
                //     default:
                //         date = this.generateWeek()
                //         break;
                // }
                // for (let i = 0; i < date.length; i++) {
                //     let score = this.$_.random(0, 120);
                //     data.push(score);
                // }
                date = this.user_study.chart_data.x_data;
                data = this.user_study.chart_data.y_data;
                let echarts = this.$echarts.init(this.$refs.trend);
                let option = {
                    tooltip: {
                        trigger: 'axis',
                        backgroundColor: '#6340c8',
                        extraCssText: 'border-radius: 8px',
                        borderColor: '#6340c8',
                        padding: [5, 10],
                        formatter: this.trendType === 7 && '{c}分' || '{b}<br>{c}分',
                        textStyle: {
                            color: '#fff',
                            height: '24px',
                            fontSize: 14,
                            fontFamily: 'PingFang SC'
                        },
                        axisPointer: {
                            lineStyle: {
                                color: 'rgba(152, 126, 232, 0.26)'
                            }
                        },
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: date,
                        offset: 18,
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        nameTextStyle: {
                            color: '#282828',
                            fontFamily: 'PingFang SC'
                        }
                    },
                    yAxis: {
                        type: 'value',
                        scale: true,
                        offset: 5,
                        max: 120,
                        min: 0,
                        interval: 10,
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            lineStyle: {
                                // 使用深浅的间隔色
                                color: ['rgba(233,233,244,0.5)'],
                                type: 'dashed'
                            }
                        },
                        nameTextStyle: {
                            color: '#282828',
                            fontFamily: 'PingFang SC'
                        }
                    },
                    grid: {
                        show: false,
                        top: '2%',
                    },
                    series: [
                        {
                            type: 'line',
                            data: data,
                            symbol: 'circle',
                            showSymbol: false,
                            symbolSize: 10,
                            smooth: true,
                            itemStyle: {
                                color: '#987ee8',
                                borderWidth: 2,
                                borderColor: '#fff',
                                shadowColor: 'rgba(99, 64, 200, 0.53)',
                                shadowBlur: 20
                            },
                            areaStyle: {
                                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: 'rgba(152, 126, 232, 0.8)'
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgba(152, 126, 232, 0)'
                                    }
                                ])
                            },
                        }
                    ],
                    dataZoom: [
                        {
                            type: 'slider',
                            start: 0,
                            end: this.trendType === 7 && 100 || 20,
                            minSpan: this.trendType === 7 && 100 || 20,
                            show: this.trendType != 7,
                            height: 20,
                            bottom: 4,
                        }, {type: 'slider', show: false}
                    ],
                };
                echarts.setOption(option);
            },
            generateWeek() {
                let nowTime = new Date().getTime(); //当前的时间戳
                let weekDay = new Date().getDay(); //当前星期 0.1.2.3.4.5.6 【0 = 周日】
                let oneDayTime = 24 * 3600 * 1000;
                ; //一天的总ms
                let weeks = [];
                for (let i = 1; i <= 7; i++) {
                    weeks.push(this.$day((i - weekDay) * oneDayTime + nowTime).format('YYYY/MM/DD'))
                }
                return weeks;
            },
            async changeTrend(val) {
                this.loading = true;
                this.trendType = val;
                this.searchForm.ach_time = val;
                await this.getUserDetail();
                this.trendEcharts();
                setTimeout(() => {
                    this.loading = false;
                }, 1200);
                this.$forceUpdate();
            },
            async changeScore(val) {
                this.loading = true;
                this.scoreType = val;
                this.searchForm.ach_type = val;
                await this.getUserDetail();
                this.trendEcharts();
                setTimeout(() => {
                    this.loading = false;
                }, 1200);
                this.$forceUpdate();
            },
            /**初始化 */
            async init() {

                const res = await studentsInfo(this.$route.params.id);
                if (res.status == 200) {
                    this.baseInfo = res.data;
                    this.searchForm.grade = this.baseInfo.sccla_grade == '' ? 0 : this.baseInfo.sccla_grade;
                    this.searchForm.semester = this.baseInfo.sccla_grade == '' ? 1 : this.baseInfo.semester;

                }


            },
            async getUserDetail() {
                this.searchForm.stuser_id = this.$route.params.id;
                const res = await getUserStudy(this.searchForm)
                if (res.status == 200) {
                    this.user_study = res.data;
                    this.performanceEcharts();
                    this.trendEcharts();
                }
            }
        },
    }
</script>